I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {data_theme: {dark_mode: "Dunkelmodus",light_mode: "Hellmodus"}});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {data_theme: {dark_mode: "Dark mode",light_mode: "Light mode"}});

$(document).on('load turbolinks:load', function() {
    'use strict';

    // ------------------------------------------------------- //
    // Dark-mode / Sidenavbar
    // ------------------------------------------------------ //
    $(function () {

        var mp5DashboardTheme = "",
            mp5DashboardSideNavState = "",
            mp5DashboardOrderListView = "";
            mp5DashboardSideNavState = localStorage.getItem(gon.user+'_sideNavState'),
            mp5DashboardOrderListView = localStorage.getItem(gon.user+'_custOrderListView'),
            mp5DashboardTheme = localStorage.getItem(gon.user+'_dashboardTheme') ? localStorage.getItem(gon.user+'_dashboardTheme') : localStorage.getItem('last_dashboardTheme_setting') ? localStorage.getItem('last_dashboardTheme_setting') : '' ;

        if (!mp5DashboardOrderListView && $('#display-list').data('customer-managed')) {
            $('#customer_card_grid .card-deck.all_orders_view').addClass('list-view')
            $('#display-grid').removeClass('active');
            $('#display-list').addClass('active');
        }
        if (mp5DashboardTheme == 'light') {
            $('html').attr('dashboard-theme', 'light').attr('data-bs-theme', 'light');
            $('#darkMode').attr('dashboard-theme','light').attr('data-bs-title', I18n.t('data_theme.dark_mode'));
            $('#darkMode span').text(I18n.t('data_theme.dark_mode'));
            $('#darkMode i').removeClass('ico-lightmode');
            $('#darkMode i').addClass('ico-darkmode');
        } else if (mp5DashboardTheme == 'dark') {
            $('html').attr('dashboard-theme', 'dark').attr('data-bs-theme', 'dark');
            $('#darkMode').attr('dashboard-theme','dark').attr('data-bs-title', I18n.t('data_theme.light_mode'));
            $('#darkMode span').text(I18n.t('data_theme.light_mode'));
            $('#darkMode i').removeClass('ico-darkmode');
            $('#darkMode i').addClass('ico-lightmode');
        }

        if ((mp5DashboardOrderListView == 'ListView') || (window.matchMedia('(max-width: 992px)').matches))  {
            $('#customer_card_grid .card-deck.all_orders_view').addClass('list-view');
            $('#display-grid').removeClass('active');
            $('#display-list').addClass('active');
        }

        $('#lock-navbar').on('click', function(e) {
            e.preventDefault();
            if (window.matchMedia('(max-width: 1025px)').matches) {
                $(this).toggleClass('close');
                $('.default-sidebar').toggleClass('active');
                $('.default-sidebar .side-navbar').toggleClass('active');
            }
            if (window.matchMedia('(min-width: 1025px)').matches) {
                $(this).toggleClass('close');
                if ($('.default-sidebar').hasClass('active')) {
                    $('.default-sidebar').removeClass('active');
                    $('.default-sidebar .side-navbar').removeClass('active');
                    $('.content-inner').removeClass('active');
                } else {
                    $('.default-sidebar').addClass('active');
                    $('.default-sidebar .side-navbar').addClass('active');
                    $('.content-inner').addClass('active');
                }
            }
        });

        $('#toggle-menu-btn').on('click', function(e) {
            $('.side-navbvar-container').toggleClass('active');
            $(this).find('.hamburgerRotate').toggleClass('active');
        });


        $('#darkMode').on('click', function(e) {
            e.preventDefault();
            $(this).blur();
            $('html').attr('dashboard-theme', function(index, attr) {
                attr = attr == 'dark' ? 'light' : 'dark';
                if (attr == 'dark') {
                    $('#darkMode').attr('data-bs-title', I18n.t('data_theme.light_mode'));
                    $('#darkMode span').text(I18n.t('data_theme.light_mode'));
                    $('#darkMode i').removeClass('ico-darkmode');
                    $('#darkMode i').addClass('ico-lightmode');
                } else if (attr == 'light') {
                    $('#darkMode').attr('data-bs-title', I18n.t('data_theme.dark_mode'));
                    $('#darkMode span').text(I18n.t('data_theme.dark_mode'));
                    $('#darkMode i').removeClass('ico-lightmode');
                    $('#darkMode i').addClass('ico-darkmode');
                }
                $('html').attr('dashboard-theme', attr).attr('data-bs-theme', attr);
                $('#darkMode').attr('dashboard-theme', attr);
                $('#recaptchaWrapper').attr('dashboard-theme', attr);
                $('.g-recaptcha').attr('dashboard-theme', attr);

                if (gon.user){
                    localStorage.setItem(gon.user + '_dashboardTheme', attr);
                } else {
                    localStorage.setItem('last_dashboardTheme_setting', attr);
                }
                return attr;
            });
        });

        $('#fullScreenMode').on('click', function(e) {
            e.preventDefault();
            $(this).blur();
            let fullScreenStatus = document.fullscreenElement;
            let myDocument = document.documentElement;
            if(!fullScreenStatus){
                if(myDocument.requestFullscreen){
                    myDocument.requestFullscreen();
                }
                else if(myDocument.msRequestFullscreen){
                    myDocument.msRequestFullscreen();
                }
                else if(myDocument.mozRequestFullscreen){
                    myDocument.mozRequestFullscreen();
                }
                else if(myDocument.webkitRequestFullscreen){
                    myDocument.webkitRequestFullscreen();
                }
            } else {
                if(document.exitFullscreen){
                    document.exitFullscreen();
                }
                else if(document.msexitFullscreen){
                    document.msexitFullscreen();
                }
                else if(document.mozexitFullscreen){
                    document.mozexitFullscreen();
                }
                else if(document.webkitexitFullscreen){
                    document.webkitexitFullscreen();
                }
            }
        });

        $('.logout').on('click',function(){
            localStorage.setItem("last_dashboardTheme_setting",$('html').attr('dashboard-theme'));
        });

        $(document).on('click', '.search-btn', function(){
            let searchFilterTarget = $(this).closest('.order-search-filter-wrapper');
            if (searchFilterTarget.length) {
                if (searchFilterTarget.hasClass('active')){
                    if (!$(this).attr('id')) {
                        searchFilterTarget.find('form').submit();
                    }
                }
            } else {
                let searchFilterTarget = $(this).closest('.analytics-search-filter-wrapper');
                let inputSearchVal = $(this).closest('#search-box').find('input.search_input').val();
                if (!searchFilterTarget.hasClass('active')){
                    searchFilterTarget.find('input.search_input').focus();
                }
                if (!inputSearchVal) {
                    searchFilterTarget.toggleClass('active');
                }
            }

        });

        $(document).on('keyup change', '.analytics-search-filter-wrapper input.search_input', function(e){
            var inputValue = $(this).val();
            var wrapperElement = $(this).closest('.analytics-search-filter-wrapper');
            if (inputValue.length) {
                wrapperElement.find('#reset-search').show();
            } else {
                $(this).removeAttr('data-example');
                wrapperElement.find('#reset-search').hide();
            }
        });

        jQuery(document).on('keydown', '.analytics-search-filter-wrapper input.search_input', function(e){
            let inputValue = jQuery(this).val();
            let searchFilterTarget = jQuery(this).closest('.analytics-search-filter-wrapper');
            if (e.keyCode == 13) {
                e.preventDefault();
                e.stopPropagation();
                if (inputValue.length) {
                    searchFilterTarget.find('form').submit();
                }
            }
        });

        $('#display-grid,#display-list').on('click', function(e) {
            e.preventDefault();
            let storageValue = $('#customer_card_grid .card-deck.all_orders_view').hasClass('list-view') ? "GridView" : "ListView";
            $('#customer_card_grid .card-deck.all_orders_view').toggleClass('list-view');
            $('#display-grid').toggleClass('active');
            $('#display-list').toggleClass('active');
            localStorage.setItem(gon.user + '_custOrderListView', storageValue);
        });

        $(window).on('scroll', function() {
            var scroll = $(window).scrollTop();
            if (scroll >= 200) {
                $(".headerNavigation").addClass("withBlur");
            } else {
                $("nav.scrollNav").removeClass("withBlur");
            }
        });


        const forms = document.querySelectorAll('.requires-validation')
        Array.from(forms)
            .forEach(function (form) {
                form.addEventListener('submit', function (event) {
                    if (!form.checkValidity()) {
                        event.preventDefault()
                        event.stopPropagation()
                    }

                    form.classList.add('was-validated')
                }, false);
            });

        $('.form-check-input.is-invalid').on('change', function (e){
            $("input[name="+$(this).attr('name')+"]").removeClass('is-invalid');
        });

        var contextMenuParent = $(".context-menu");
        var contextMenu = $(".context-menu .dropdown-menu");

        $(document).on("contextmenu", ".card-deck .mp-card", function(e) {
            showContextMenu(e, e.target);
            return false;
        });

        $('.card-deck .mp-card').on('mousedown', function(e) {
            if (e.which === 2 || (e.which === 1 && (e.ctrlKey || e.metaKey))) {
                e.preventDefault();
                var url = $(this).data('order-url');
                window.open(url, '_blank');
            }
        });

        $(document).on("touchstart", ".card-deck .mp-card", function(e) {
            if (e.touches.length === 2) {
                e.preventDefault();
                var touch1 = e.touches[0];
                var touch2 = e.touches[1];
                var midPoint = {
                    clientX: (touch1.clientX + touch2.clientX) / 2,
                    clientY: (touch1.clientY + touch2.clientY) / 2,
                    target: e.target
                };

                showContextMenu(midPoint, e.target);
            }
        });

        $('html').on('touch click', function() {
            contextMenu.not(".show").removeAttr('style');
            contextMenuParent.removeAttr('style');
        });

        function showContextMenu(e, targetElement) {
            let rect = targetElement.getBoundingClientRect();
            let x = (e.clientX - rect.left).toFixed();
            let y = (e.clientY - rect.top).toFixed();

            contextMenu.removeAttr('style');
            contextMenu.each(function() {
                $(this).removeClass('show');
            });
            contextMenuParent.removeAttr('style');
            $(targetElement).closest('.card-deck .mp-card').find(contextMenuParent).attr('style','position: static;');
            $(targetElement).closest('.card-deck .mp-card').find(contextMenu).attr('style','display:block;left:auto;left:'+ x + 'px!important;top:' + y + 'px!important;');
        }

        $(document).on('touch click contextmenu', 'html', function(event) {

            if (!$(event.target).closest('.order-search-filter-wrapper').length) {
                var searchInput = $('.order-search-filter-wrapper #search_by_oid');
                if ((!searchInput.length || !searchInput.val().length > 0) && $('.order-search-filter-wrapper.active').length) {
                    $('.order-search-filter-wrapper.active').removeClass('active');
                }
            }

            if (!$(event.target).closest('.analytics-search-filter-wrapper').length) {
                $('.analytics-search-filter-wrapper.active').each(function() {
                    var wrapper = $(this);
                    var searchInput = wrapper.find('.search_input');
                    if (!searchInput.length || !searchInput.val().length > 0) {
                        wrapper.removeClass('active');
                        wrapper.find('#reset-search').hide();
                    }
                });

            }

        });

        $(document).on('click', '.search-btn', function(){
            var searchFilterTarget = $(this).closest('.order-search-filter-wrapper');
            if (searchFilterTarget.hasClass('active')){
                var inputValue = searchFilterTarget.find('form #search_by_oid').val().trim();
                if (inputValue != '') {
                    searchFilterTarget.find('form').submit();
                }
            } else {
                searchFilterTarget.toggleClass('active');
            }
        });

        $(".context-menu dropdown-menu li a").click(function(e){
            var  e = $(this);
        });

        if ($('#live-order-name-change').length > 0) {
            $('#live-order-name-change').on('input', function () {
                var inputValue = $(this).val();
                if ($('#live-order-name-placeholder').length > 0) {
                    $('#live-order-name-placeholder').text(inputValue);
                    $('#live-order-name-placeholder').attr('data-bs-title',`${$('#live-order-name-placeholder').attr('data-title-prefix')}: ${inputValue}`);
                    $('#live-order-name-placeholder').tooltip('dispose').tooltip();
                    $('#edit-order-name-dialog input#fb_order_name').val(inputValue);
                }
            });
        }

    });

    $('#MP5_autoSaveFormBuilder').on('click', function() {
        window.mpSaveButtonClicked(true);
    });

    $('.load-order-template-modal').on('click', loadOrderTemplateModalContent);
    $(document).on('click', '.load-template-overview', loadOrderTemplateModalContent);
    $(document).on('click', '#show-order-template-summary', backToTemplateSummary);
    $(document).on('click', '#template-preview-modal .btn-close', cleanTemplateContainer);
    $(document).on('click', '#template-modal .btn-close', function(event) {cleanTemplateModalbackdrops(0);});
    $(document).on('click', '#use-order-template', setTemplateIdAndSubmit);

    function loadOrderTemplateModalContent(event) {
        event.preventDefault();
        var el = $(event.currentTarget);
        var templateOverview = el.data('template-overview');
        var url, target_modal, target_modal_append_area;

        if (templateOverview && templateOverview.length > 0){
            var url = templateOverview;
            var target_modal = $('#template-preview-modal');
            var modal_loader = $('#template-preview-modal .modal_loader');
            var target_modal_append_area = $('#template-preview-modal .modal-content .modal-body');
            target_modal.addClass('loading-data');
        } else {
            var url = el.data('url') || el.attr('href');
            var target_modal = $('#template-modal');
            var modal_loader = $('#template-modal .modal_loader');
            var target_modal_append_area = $('#template-modal .modal-content');
        }
        $('.modal').modal('hide');
        target_modal_append_area.empty();
        target_modal.modal('show');
        modal_loader.html('<div id="loader-spinner"></div>');

        var debouncedAjaxCall = _.debounce(function(el) {
            $.ajax({
                url: url,
                method: 'GET',
                dataType: 'html',
                success: function(data){
                    target_modal_append_area.html(data);
                    $('.modal #loader-spinner').length > 0 ? modal_loader.empty() : '';
                    target_modal.removeClass('loading-data').modal('show');
                },
                error: function(xhr, status, error) {
                    console.error('Error during the template render', textStatus, errorThrown);
                },
            }).fail((xhr, status, error) => {
                console.error({message: 'Error during the template render', error: error})
            });
        }, 300);
        debouncedAjaxCall(el);
    }

    function setTemplateIdAndSubmit(event) {
        event.preventDefault();
        var id = $(event.currentTarget).data('template');
        setTimeout(() => {
            $('#order_template_id')[0].setAttribute('value', id);
            $('#new_marketplace_order').submit();
        }, 500);
    }

    function backToTemplateSummary(event){
        event.preventDefault();
        $('#template-preview-modal').modal('hide');
        $('#template-preview-modal').hide(); // fixes the bug of .modal('hide') inconsistency
        $('#template-preview-modal .modal-content .modal-body').empty();
        $('#template-modal').modal('show');
        cleanTemplateModalbackdrops(1);
    }

    function cleanTemplateContainer(event){
        event.preventDefault();
        $('#template-preview-modal').modal('hide');
        $('#template-preview-modal .modal-content .modal-body').empty();
        cleanTemplateModalbackdrops(0);
    }


    function cleanTemplateModalbackdrops(levelDepth){
        while ($('.modal-backdrop').length > levelDepth) {
            $('.modal-backdrop').last().remove();
        }
    }

    $(document).on('click', '#template-modal .filter-by-template-category', function() {
        let templateCategoryToFilter = $(this).attr('data-template-category-id');
        $('.filter-by-template-category').removeClass('active');
        $(this).addClass('active');

        if (templateCategoryToFilter === 'all') {
            $('.templates-grid .template-card-container').fadeIn(300).show();
        } else {
            $('.templates-grid .template-card-container').each(function () {
                let currentCardCategoryId = $(this).attr('data-template-category-id');
                if (currentCardCategoryId === templateCategoryToFilter) {
                    $(this).fadeIn(300).show();
                } else {
                    $(this).fadeOut(300).hide();
                }
            });
        }
    });


    $(document).on('click', '.analytics-element-card .card-header [data-collapse-target]', function(event) {
        if ($(event.target).closest('.analytics-card-settings-buttons').length) {
            return;
        } else {
            collapseAnalyticsElements($(this));
        }
    });

    function collapseAnalyticsElements(button) {
        var target = $(button.data("collapse-target"));
        if (target.hasClass("show")) {
            button.attr('aria-expanded', 'false');
            target.fadeOut(function (){
                target.removeClass("show");
            });
        } else {
            button.attr('aria-expanded', 'true');
            target.fadeIn(function (){
                target.addClass("show");
            });
        }
    }

    if (gon.voucher_coupon_code){
        localStorage.setItem('voucher_coupon_code',gon.voucher_coupon_code);
    }
    if (localStorage.getItem('voucher_coupon_code') && gon.user){
        localStorage.setItem(gon.user+'voucher_coupon_code', localStorage.getItem('voucher_coupon_code'));
        localStorage.removeItem('voucher_coupon_code');
    }
    if ($('input[name*="coupon_code"]').length > 0 && localStorage.getItem(gon.user+'voucher_coupon_code')){
        $('input[name*="coupon_code"]').val(localStorage.getItem(gon.user+'voucher_coupon_code'));
    }

    function bindScrollEvent() {
        var scrollLeft = $(this).scrollLeft();
        if (scrollLeft > 0) {
            $('.sticky-left-col').addClass('has-border-end');
        } else {
            $('.sticky-left-col').removeClass('has-border-end');
        }
    }

    $(document).on('click', '#wizard-nav-previous-step', function(e) {
        if ($(document).find('a#wizard-previous-step').attr('href')) {
            window.location.href = $(document).find('a#wizard-previous-step').attr('href');
        }
    });
    $(document).on('click', '#wizard-nav-next-step', function(e) {
        if ($(document).find('#fb-wizard-next-step').length > 0) {
            $(document).find('#fb-wizard-next-step').click();
        } else {
            $(document).find('#wizard-next-step').click();
        }
    });

    window.updateQueryStringParameter = function updateQueryStringParameter(uri, key, value) {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var mainParam = 'tasks_page';
        var mainParamMatch = uri.match(new RegExp("([?&])" + mainParam + "=([^&]*)"));
        var mainParamValue = mainParamMatch ? mainParamMatch[2] : null;
        var baseUrl = uri.split('?')[0];
        var newParams = mainParamValue ? mainParam + '=' + mainParamValue : '';
        if (uri.match(re)) {
            uri = uri.replace(re, '');
        }
        uri = uri.replace(/[?&]+$/, '');
        var otherParams = uri.split('?')[1];
        if (otherParams) {
            otherParams = otherParams.split('&').filter(p => !p.startsWith(mainParam + '=') && !p.startsWith(key + '='));
            if (otherParams.length > 0) {
                newParams += (newParams ? '&' : '') + otherParams.join('&');
            }
        }
        newParams += (newParams ? '&' : '') + key + '=' + value;
        return baseUrl + '?' + newParams;
    }

    window.getCookie = function getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    }

    $('.nav.nav-pills:not(#export-pills-tabs) [data-bs-toggle="pill"]').on('shown.bs.tab', (e) => {
        let target = $(e.target).attr('data-bs-target').replace('#', '');
        document.cookie = "activeTab=" + target + ";path=/";
    });

    let activeTab = getCookie("activeTab");
    if (activeTab && activeTab!='undefined') {
        $('.nav-link[data-bs-target="#' + activeTab + '"]').tab('show');
    }
    $(document).ready(function() {
        $('.table-responsive-h-v.table-responsive-data').on('scroll', bindScrollEvent);
    });

});

$(document).on('load turbolinks:load', function() {
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
        return new bootstrap.Dropdown(dropdownToggleEl)
    });

    $(document).on('click', '#showPassword', function() {
        $(this).toggleClass('fa-eye-slash fa-eye');
        var input = $($(this).attr('toggle'));
        input.attr('type', input.attr('type') === 'password' ? 'text' : 'password');
    });
});

