module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"card\">\n    <div class=\"card-body\">\n        <div class=\"row\">\n          ")
    ;  _(['X', 'Y']).each(function(axis) { 
    ; __append("\n            <div class=\"col-auto\">\n              <div class=\"input-group\">\n                <label class=\"input-group-text\" for=\"register_country_code\">Dimension ")
    ; __append(escapeFn( axis ))
    ; __append("</label>\n                <select id=\"")
    ; __append(escapeFn( axis.toLowerCase() ))
    ; __append("\" class=\"form-select selectpicker\">\n                  <option value=\"\">Nothing selected</option>\n                  ")
    ;  _(dimOptions).each(function(category) { 
    ; __append("\n                    ")
    ;  if(search[axis.toLowerCase()] === category.column) { 
    ; __append("\n                      <option selected=\"true\">")
    ; __append(escapeFn( category.column ))
    ; __append("</option>\n                    ")
    ;  } else { 
    ; __append("\n                      <option>")
    ; __append(escapeFn( category.column ))
    ; __append("</option>\n                    ")
    ;  } 
    ; __append("\n                  ")
    ;  }); 
    ; __append("\n                </select>\n              </div>\n            </div>\n          ")
    ;  }); 
    ; __append("\n          <div class=\"col-auto ms-auto\"><h4 class=\"video-title pull-right\">Results found: ")
    ; __append(escapeFn( resultsCount ))
    ; __append("</h4></div>\n        </div>\n\n        <div id=\"cross_table\" class=\"row\"></div>\n    </div>\n</div>\n")
  }
  return __output;

}