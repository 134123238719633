module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"card\">\n  <div class=\"card-body p-0\">\n    <form class=\"form collapse fade show p-3\" id=\"search-form\">\n        <div class=\"row align-items-end\">\n          ")
    ;  _(inputData).each(function(entry) { 
    ; __append("\n              ")
    ;  if(entry.data.input_type === 'select_option') { 
    ; __append("\n                <div class=\"col-md-3 my-2\">\n                  <label class=\"fw-bold d-block\" for=\"")
    ; __append(escapeFn( entry.dataColumn ))
    ; __append("\">")
    ; __append(escapeFn( entry.dataColumn ))
    ; __append("</label>\n                  <select id=\"")
    ; __append(escapeFn( entry.dataColumn ))
    ; __append("\" multiple=\"true\" class=\"form-select selectpicker w-100\">\n                    <option value=\"\">&lt;blank&gt;</option>\n                    ")
    ;  _(entry.data.options).each(function(option) { 
    ; __append("\n                      <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n                    ")
    ;  }) 
    ; __append("\n                  </select>\n                </div>\n              ")
    ;  } else { 
    ; __append("\n                <div class=\"col-md-3 my-2\">\n                  <label class=\"fw-bold d-block\" for=\"")
    ; __append(escapeFn( entry.dataColumn ))
    ; __append("\">")
    ; __append(escapeFn( entry.dataColumn ))
    ; __append("</label>\n                  <div class=\"input-group\">\n                    <span class=\"input-group-text\">contains</span>\n                    <input type=\"text\" id=\"")
    ; __append(escapeFn( entry.dataColumn ))
    ; __append("\" class=\"form-control input-sm\">\n                  </div>\n                </div>\n              ")
    ;  } 
    ; __append("\n          ")
    ;  }) 
    ; __append("\n\n          ")
    ;  if(VideoAnalysis.feature === 'insights' && showEventFields){ 
    ; __append("\n          <div class=\"col-md-3 my-2\">\n            <label class=\"fw-bold d-block\" for=\"preceding_events\"># preceding Events</label>\n            <select id=\"preceding_events\" class=\"form-select selectpicker w-100\">\n              <option value=\"0\">0</option>\n              <option value=\"1\">1</option>\n              <option value=\"2\">2</option>\n              <option value=\"3\">3</option>\n              <option value=\"4\">4</option>\n              <option value=\"5\">5</option>\n            </select>\n          </div>\n\n          <div class=\"col-md-3 my-2\">\n            <label class=\"fw-bold d-block\" for=\"following_events\"># following Events</label>\n            <select id=\"following_events\" class=\"form-select selectpicker w-100\">\n              <option value=\"0\">0</option>\n              <option value=\"1\">1</option>\n              <option value=\"2\">2</option>\n              <option value=\"3\">3</option>\n              <option value=\"4\">4</option>\n              <option value=\"5\">5</option>\n            </select>\n          </div>\n          ")
    ;  } 
    ; __append("\n\n          ")
    ;  if (imageTypes.length > 1) { 
    ; __append("\n          <div class=\"col-md-3 my-2\">\n            <label class=\"fw-bold d-block\" for=\"image_types\">Image Type</label>\n            <select id=\"image_types\" multiple=\"true\" class=\"form-select selectpicker w-100\">\n              ")
    ;  _(imageTypes).each(function(option) { 
    ; __append("\n                <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n              ")
    ;  }) 
    ; __append("\n            </select>\n          </div>\n          ")
    ;  } 
    ; __append("\n\n          <div class=\"col-md-3 my-2\">\n            <input class=\"btn btn-info\" type=\"submit\" value=\"Search\">\n            <button id=\"taxonomy-modal-button\" class=\"btn btn-info\" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#taxonomy-frame\">Edit taxonomy</button>\n          </div>\n        </div>\n    </form>\n  </div>\n</div>\n\n<div class=\"text-center\">\n  <a data-bs-toggle=\"collapse\" href=\"#search-form\" role=\"button\" aria-expanded=\"true\" aria-controls=\"search-form\" id=\"collapse-link\" class=\"btn btn-outline-grey collapse-link px-4\">Hide search settings</a>\n</div>\n\n<div id=\"taxonomy-frame\" class=\"modal fade w-100\" tabindex=\"-1\" data-bs-backdrop=\"static\" aria-labelledby=\"taxonomy-modal-button\" aria-hidden=\"true\"></div>\n")
  }
  return __output;

}