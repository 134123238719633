module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"modal-dialog modal-dialog-scrollable modal-xl\">\n  <form class=\"modal-content form-horizontal\" action=\"/marketplace/video_result_sets/")
    ; __append(escapeFn( video_result_set_id ))
    ; __append("\" id=\"edit_video_result_set_")
    ; __append(escapeFn( video_result_set_id ))
    ; __append("\">\n        <div class=\"modal-header\">\n          <h3 class=\"modal-title\">Taxonomy</h3>\n          <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"modal\" aria-label=\"Close\"></button>\n        </div>\n      <div class=\"modal-body pt-0\">\n          <table class=\"table table-hover align-middle\">\n            <thead>\n              <th class=\"pt-3\">Attribute</th>\n              <th class=\"pt-3\">Input type</th>\n              <th class=\"pt-3\">Show</th>\n              <th class=\"pt-3\"></th>\n            </thead>\n            <tbody>\n              ")
    ;  _(results_fields).each(function(field) { 
    ; __append("\n                <tr>\n                  <td>")
    ; __append(escapeFn( field ))
    ; __append("</td>\n                  <td>\n                    <select class=\"selectpicker form-select\" name=\"video_result_set[taxonomy_columns][")
    ; __append(escapeFn( field ))
    ; __append("][input_type]\">\n                      <option value=\"\">--</option>\n                      <option value=\"select_option\" ")
    ; __append(escapeFn( full_taxonomy[field] && full_taxonomy[field].input_type == "select_option" ? "selected" : null ))
    ; __append(">Select</option>\n                      <option value=\"text_field\" ")
    ; __append(escapeFn( full_taxonomy[field] && full_taxonomy[field].input_type == "text_field" ? "selected" : null ))
    ; __append(">Text field</option>\n                    </select>\n                  </td>\n                  <td>\n                    <input name=\"video_result_set[taxonomy_columns][")
    ; __append(escapeFn( field ))
    ; __append("][display]\" type=\"checkbox\" value=\"true\" ")
    ; __append(escapeFn( full_taxonomy[field] && full_taxonomy[field].display !== "" ? "checked" : null ))
    ; __append(" >\n                  </td>\n                  <td>\n                    <i class=\"fa fa-trash pull-right clear-row fs-5 text-muted\"></i>\n                  </td>\n                </tr>\n              ")
    ;  }) 
    ; __append("\n            </tbody>\n          </table>\n        </div>\n        <div class=\"modal-footer justify-content-end\">\n          <div class=\"btn btn-md fw-light btn-outline-grey\" data-bs-dismiss=\"modal\">Close</div>\n          <button class=\"btn btn-md btn-info\" id=\"save-taxonomy\">Save</button>\n        </div>\n    </form>\n</div>\n")
  }
  return __output;

}