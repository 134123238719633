module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"row\">\n  ")
    ;  if (videoEvents) { 
    ; __append("\n    <div class=\"col-md-")
    ; __append(escapeFn( showTimestamp ? '3' : '2' ))
    ; __append("\">\n      ")
    ;  if (videoEvents.length > 0) { 
    ; __append("\n        <div class=\"results-list overflow-auto\">\n            <div class=\"table-responsive-lg\">\n                <table class=\"table table-hover align-middle\">\n                <tbody class=\"p-0\">\n                  ")
    ;  _(videoEvents).each(function(entry) { 
    ; __append("\n                    <tr>\n                      <td class=\"p-0\">\n                        ")
    ;  filename = entry.get(controlColumns.url_column[0]) 
    ; __append("\n                        ")
    ;  colname = entry.get(controlColumns.video_column_name) 
    ; __append("\n                        ")
    ;  if (colname || filename) { 
    ; __append("\n                          <a href=\"javascript:void(0)\" data-event-id=\"")
    ; __append(escapeFn( entry.get('_id') ))
    ; __append("\" class=\"show-event-link p-2 d-block\">\n                            ")
    ; __append(escapeFn( colname || filename.substring(filename.lastIndexOf('/') + 1) ))
    ; __append("\n                          </a>\n                        ")
    ;  } else { 
    ; __append("\n                          NA\n                        ")
    ;  } 
    ; __append("\n                      </td>\n                      ")
    ;  if (showTimestamp) { 
    ; __append("\n                        <td>\n                          ")
    ;  if (filename && filename.match(/\.(jpeg|jpg|gif|png)(?:$|\?)$/i) === null && controlColumns.timestamp_start && entry.get(controlColumns.timestamp_start) && controlColumns.timestamp_end && entry.get(controlColumns.timestamp_end)) { 
    ; __append("\n                            ")
    ; __append(escapeFn( entry.get(controlColumns.timestamp_start).toMMSS() ))
    ; __append(" - ")
    ; __append(escapeFn( entry.get(controlColumns.timestamp_end).toMMSS() ))
    ; __append("\n                          ")
    ;  } 
    ; __append("\n                        </td>\n                      ")
    ;  } 
    ; __append("\n                    </tr>\n                  ")
    ;  }) 
    ; __append("\n                </tbody>\n              </table>\n            </div>\n        </div>\n      ")
    ;  } 
    ; __append("\n    </div>\n  ")
    ;  } 
    ; __append("\n\n  <div id=\"current_event\" class=\"col-md-10\"></div>\n</div>\n")
  }
  return __output;

}