window.bindBootstrapFormValidation = function() {
    'use strict';

    var forms = $(document).find('.needs-validation.validation-with-animation');

    Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                formError(form);
            }
            form.classList.add('was-validated');
        }, false);
    });
};

function formError(formElement) {
    var target = $(formElement).closest('.validation-with-animation-container').length > 0
        ? $(formElement).closest('.validation-with-animation-container')
        : $(formElement);
    target.addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        $(this).removeClass('shake animated');
    });
}

$(document).ready(function() {
    window.bindBootstrapFormValidation();
});
