module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"table-responsive-lg\">\n  <table class=\"table table-hover align-middle\">\n      <thead>\n        <tr>\n          <th></th>\n          ")
    ;  _(xOptions).each(function(option) { 
    ; __append("\n            <th>")
    ; __append(escapeFn( option ))
    ; __append("</th>\n          ")
    ;  }) 
    ; __append("\n        </tr>\n      </thead>\n      <tbody>\n        ")
    ;  _(tableData).each(function(entry, key) { 
    ; __append("\n          <tr>\n            <th scope=\"row\">")
    ; __append(escapeFn( key ))
    ; __append("</th>\n            ")
    ;  i = 0 
    ; __append("\n            ")
    ;  _(entry).each(function(value, header, self) { 
    ; __append("\n              <td>")
    ; __append(escapeFn( self[xOptions[i]] ))
    ; __append("</td>\n              ")
    ;  i = i + 1 
    ; __append("\n            ")
    ;  }); 
    ; __append("\n          </tr>\n        ")
    ;  }); 
    ; __append("\n      </tbody>\n    </table>\n</div>\n")
  }
  return __output;

}