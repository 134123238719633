import "core-js/stable";
import "regenerator-runtime/runtime";
import '@popperjs/core';
import 'bootstrap/dist/js/bootstrap.bundle';
import "stylesheets/application";
import './team_user_management/team_user_management.js.coffee'
import './video_analysis/video_analysis.js.coffee'
import Turbolinks from 'turbolinks';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import * as jquery from 'jquery';
import './bootstrap-select-turbolinks-fix';
import './marketplace/autohide/autohide';
import './marketplace/usability/useablity_helper';
import 'javascripts/jquery/jquery.printElement.min';
import './marketplace/shared_lib/form_validation_with_animation';

window.bootstrap = bootstrap;

window.$ = window.jQuery = jquery;

require('bootstrap-select');

Rails.start();
Turbolinks.start();

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
