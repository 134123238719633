module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"col-md-4 offset-md-2\">\n  <table class=\"table table-hover\">\n    <thead>\n      <th class=\"text-center\">Preceding Events</th>\n    </thead>\n    <tbody>\n      ")
    ;  precedingEventCounts = tableData.preceding 
    ; __append("\n      ")
    ;  _(precedingEventCounts).each(function(entry){ 
    ; __append("\n        <tr>\n          <td>")
    ; __append(escapeFn( entry.event ))
    ; __append(": <span class=\"badge pull-right\">")
    ; __append(escapeFn( entry.count ))
    ; __append("</span></td>\n        </tr>\n      ")
    ;  }) 
    ; __append("\n    </tbody>\n  </table>\n</div>\n<div class=\"col-md-4\">\n  <table class=\"table\">\n    <thead>\n      <th class=\"text-center\">Following Events</th>\n    </thead>\n    <tbody>\n      ")
    ;  followingEventCounts = tableData.following 
    ; __append("\n      ")
    ;  _(followingEventCounts).each(function(entry){ 
    ; __append("\n        <tr>\n          <td>")
    ; __append(escapeFn( entry.event ))
    ; __append(": <span class=\"badge pull-right\">")
    ; __append(escapeFn( entry.count ))
    ; __append("</span></td>\n        </tr>\n      ")
    ;  }) 
    ; __append("\n    </tbody>\n  </table>\n</div>\n")
  }
  return __output;

}