module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append("<div class=\"url-control-bar\">\n  <a class=\"btn btn-sm url-prev ")
    ; __append(escapeFn( position == 1 ? 'disabled' : '' ))
    ; __append("\">\n    <i class=\"fa fa-chevron-circle-left\"></i>\n  </a>\n\n  <span class=\"text-muted\">\n    <small>")
    ; __append(escapeFn( position ))
    ; __append(" / ")
    ; __append(escapeFn( totalUrls ))
    ; __append("</small>\n  </span>\n\n  <a class=\"btn btn-sm url-next ")
    ; __append(escapeFn( position == totalUrls ? 'disabled' : '' ))
    ; __append("\">\n    <i class=\"fa fa-chevron-circle-right\"></i>\n  </a>\n</div>\n")
  }
  return __output;

}