module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if((typeof gon.taxonomy.event != 'undefined')&&(gon.taxonomy.event.input_type === 'select_option')) { 
    ; __append("\n  <div class=\"row\">\n    <div class=\"col-md-3\">\n      <div class=\"input-group\">\n        <label for=\"preceding_events\" class=\"fw-bold d-block\"># preceding Events</label>\n        <select id=\"preceding_events\" class=\"form-select selectpicker w-100\">\n          <option value=\"\">Nothing selected</option>\n          ")
    ;  _([0,1,2,3,4,5]).each(function(option) { 
    ; __append("\n            ")
    ;  if(parseInt(search.precedingEventsNumber) === option) { 
    ; __append("\n              <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\" selected=\"true\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n            ")
    ;  } else { 
    ; __append("\n              <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n            ")
    ;  } 
    ; __append("\n          ")
    ;  }) 
    ; __append("\n        </select>\n      </div>\n    </div>\n\n    <div class=\"col-md-3\">\n      <div class=\"input-group\">\n        <label for=\"event_select\" class=\"fw-bold d-block\">Event</label>\n        <select id=\"event_select\" class=\"form-select selectpicker w-100\">\n          <option value=\"\">Nothing selected</option>\n          ")
    ;  _(gon.taxonomy.event.options).each(function(option) { 
    ; __append("\n            ")
    ;  if(search.eventType === option) { 
    ; __append("\n              <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\" selected=\"true\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n            ")
    ;  } else { 
    ; __append("\n              <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n            ")
    ;  } 
    ; __append("\n          ")
    ;  }) 
    ; __append("\n        </select>\n      </div>\n    </div>\n\n    <div class=\"col-md-3\">\n      <div class=\"input-group\">\n        <label for=\"following_events\" class=\"fw-bold d-block\"># following Events</label>\n        <select id=\"following_events\" class=\"form-select selectpicker w-100\">\n          <option value=\"\">Nothing selected</option>\n          ")
    ;  _([0,1,2,3,4,5]).each(function(option) { 
    ; __append("\n            ")
    ;  if(parseInt(search.followingEventsNumber) === option) { 
    ; __append("\n              <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\" selected=\"true\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n            ")
    ;  } else { 
    ; __append("\n              <option value=\"")
    ; __append(escapeFn( option ))
    ; __append("\">")
    ; __append(escapeFn( option ))
    ; __append("</option>\n            ")
    ;  } 
    ; __append("\n          ")
    ;  }) 
    ; __append("\n        </select>\n      </div>\n    </div>\n\n    <div class=\"col-md-3\">\n      <h4 class=\"video-title pull-right\">Results found: ")
    ; __append(escapeFn( videoEvents.length ))
    ; __append("</h4>\n    </div>\n  </div>\n  <hr>\n  <div id=\"funnel_table\" class=\"col-md-12\"></div>\n\n")
    ;  }else{ 
    ; __append("\n  <p><i>You should set 'Event' column as <b>select</b> on define taxonomy page</i></p>\n")
    ;  } 
    ; __append("\n")
  }
  return __output;

}