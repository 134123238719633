module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if(currentEvent) { 
    ; __append("\n  <a class=\"collapse-link toggle-event pull-left\" title=\"Show event\" style=\"display:")
    ; __append(escapeFn( eventHidden ? 'block' : 'none' ))
    ; __append("\">\n    ")
    ; __append(escapeFn( "<" ))
    ; __append("\n  </a>\n  <div id=\"event-panel\" class=\"col-md-4 wrap-words\" style=\"display:")
    ; __append(escapeFn( eventHidden ? 'none' : 'block' ))
    ; __append("\">\n    <div class=\"panel-group\" id=\"accordion\">\n      ")
    ;  _(currentEvent.get('precedingEvents')).each(function(entry) { 
    ; __append("\n        <div class=\"panel panel-default\">\n          <div class=\"panel-heading\">\n            <h4 class=\"panel-title\">\n              <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#panel_")
    ; __append(escapeFn( entry._id ))
    ; __append("\">\n                <span class=\"timespan\">")
    ; __append(escapeFn( entry[controlColumns.timestamp_start].toMMSS() ))
    ; __append(" - ")
    ; __append(escapeFn( entry[controlColumns.timestamp_end].toMMSS() ))
    ; __append("</span>\n                ")
    ; __append(escapeFn( entry[controlColumns.event] ))
    ; __append("\n              </a>\n            </h4>\n          </div>\n          <div id=\"panel_")
    ; __append(escapeFn( entry._id ))
    ; __append("\" class=\"panel-collapse collapse\">\n            <div class=\"panel-body\">\n\n            <a class=\"btn btn-sm btn-info pull-right play-event\" data-event-type=\"near\" data-event-id=\"")
    ; __append(escapeFn( entry._id ))
    ; __append("\" href=\"#\">\n              <i class=\"fa fa-play-circle-o\"></i>&nbsp;play\n            </a>\n\n              <dl class=\"dl-horizontal\">\n                ")
    ;  for (var property in entry) { 
    ; __append("\n                  ")
    ;  if (entry.hasOwnProperty(property) && !hiddenAttrs.includes(property)) { 
    ; __append("\n                    <dt>")
    ; __append(escapeFn( property ))
    ; __append(":</dt>\n                    <dd>")
    ; __append(escapeFn( entry[property] ))
    ; __append("</dd>\n                  ")
    ;  } 
    ; __append("\n                ")
    ;  } 
    ; __append("\n              </dl>\n            </div>\n          </div>\n        </div>\n      ")
    ;  }) 
    ; __append("\n\n      <div class=\"panel panel-info\">\n        <div class=\"panel-heading\">\n          <h4 class=\"panel-title clearfix\">\n            <span>Results ")
    ; __append(escapeFn( currentEvent.collection.length ))
    ; __append("</span>\n            <a class=\"toggle-event pull-right\" style=\"display: none\">\n              ")
    ; __append(escapeFn( ">" ))
    ; __append("\n            </a>\n            <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#current\">\n              ")
    ;  if (isVideo && currentEvent.get(controlColumns.timestamp_start) && currentEvent.get(controlColumns.timestamp_end)) { 
    ; __append("\n                <span class=\"timespan\">")
    ; __append(escapeFn( currentEvent.get(controlColumns.timestamp_start).toMMSS() ))
    ; __append(" - ")
    ; __append(escapeFn( currentEvent.get(controlColumns.timestamp_end).toMMSS() ))
    ; __append("</span>\n              ")
    ;  } 
    ; __append("\n              ")
    ; __append(escapeFn( currentEvent.get(controlColumns.event) ))
    ; __append("\n            </a>\n          </h4>\n        </div>\n        <div id=\"current\" class=\"panel-collapse collapse in\">\n          <div class=\"panel-body text-left\">\n            ")
    ;  if (isVideo) { 
    ; __append("\n              <a class=\"btn btn-sm btn-info pull-right play-event\" data-event-type=\"current\" href=\"#\">\n                <i class=\"fa fa-play-circle-o\"></i>&nbsp;play\n              </a>\n            ")
    ;  } 
    ; __append("\n            <dl class=\"dl-horizontal\">\n              ")
    ;  if (controlColumns.video_column_name) { 
    ; __append("\n                <dt>")
    ; __append(escapeFn( controlColumns.video_column_name ))
    ; __append(":</dt>\n                <dd>")
    ; __append(escapeFn( currentEvent.get(controlColumns.video_column_name) ))
    ; __append("</dd>\n              ")
    ;  } 
    ; __append("\n              ")
    ;  _.each(currentEvent.attrsToDisplay(gon.setup), function(property) { 
    ; __append("\n                ")
    ;  if (currentEvent.attributes.hasOwnProperty(property) && !hiddenAttrs.includes(property) ) { 
    ; __append("\n                  <dt>")
    ; __append(escapeFn( property ))
    ; __append(":</dt>\n                  <dd>")
    ; __append(escapeFn( currentEvent.get(property) ))
    ; __append("</dd>\n                ")
    ;  } 
    ; __append("\n              ")
    ;  }); 
    ; __append("\n            </dl>\n          </div>\n        </div>\n      </div>\n\n      ")
    ;  _(currentEvent.get('followingEvents')).each(function(entry) { 
    ; __append("\n        <div class=\"panel panel-default\">\n          <div class=\"panel-heading\">\n            <h4 class=\"panel-title\">\n              <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#panel_")
    ; __append(escapeFn( entry._id ))
    ; __append("\">\n                <span class=\"timespan\">")
    ; __append(escapeFn( entry[controlColumns.timestamp_start].toMMSS() ))
    ; __append(" - ")
    ; __append(escapeFn( entry[controlColumns.timestamp_end].toMMSS() ))
    ; __append("</span>\n                ")
    ; __append(escapeFn( entry[controlColumns.event] ))
    ; __append("\n              </a>\n            </h4>\n          </div>\n          <div id=\"panel_")
    ; __append(escapeFn( entry._id ))
    ; __append("\" class=\"panel-collapse collapse\">\n            <div class=\"panel-body\">\n\n            <a class=\"btn btn-sm btn-info pull-right play-event\" data-event-type=\"near\" data-event-id=\"")
    ; __append(escapeFn( entry._id ))
    ; __append("\" href=\"#\">\n              <i class=\"fa fa-play-circle-o\"></i>&nbsp;play\n            </a>\n\n              <dl class=\"dl-horizontal\">\n                ")
    ;  for (var property in entry) { 
    ; __append("\n                  ")
    ;  if (entry.hasOwnProperty(property) && !hiddenAttrs.includes(property)) { 
    ; __append("\n                    <dt>")
    ; __append(escapeFn( property ))
    ; __append(":</dt>\n                    <dd>")
    ; __append(escapeFn( entry[property] ))
    ; __append("</dd>\n                  ")
    ;  } 
    ; __append("\n                ")
    ;  } 
    ; __append("\n              </dl>\n            </div>\n          </div>\n        </div>\n      ")
    ;  }) 
    ; __append("\n    </div>\n\n  </div>\n\n  <div id=\"media-container\" class=\"wrap-words panel panel-default\">\n    ")
    ;  if (filename) { 
    ; __append("\n      <div class=\"col-sm-12 panel-heading\">\n        <div class=\"img-nav heading pull-left\"></div>\n        <div class=\"panel-title\">\n          <b>")
    ; __append(escapeFn( column ))
    ; __append(":</b>\n          <a href=\"")
    ; __append(escapeFn( filename ))
    ; __append("\" target=\"_blank\">")
    ; __append(escapeFn( filename.substring(filename.lastIndexOf('/') + 1) ))
    ; __append("</a>\n        </div>\n      </div>\n    ")
    ;  }else{ 
    ; __append("\n      <div class=\"col-sm-12\">\n        <b>")
    ; __append(escapeFn( column ))
    ; __append(":</b>\n        no file attached.\n      </div>\n    ")
    ;  } 
    ; __append("\n    <div id=\"video-container\"></div>\n    <div class=\"col-sm-12 img-nav\">\n  </div>\n")
    ;  } 
    ; __append("\n")
  }
  return __output;

}